@import-normalize;

html, body {
  font-family: 'Courier New', Courier, monospace;
  margin: 0;
  padding: 0;
  height: 100%; /* Ensures the body can fill the whole viewport height */
  width: 100%;  /* Ensures the body can fill the whole viewport width */
  /* overflow: hidden; */
}

#root {
  background-color: #FFFBF8;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box; 
  min-height: 100%;
  /* border: 2em solid black;   */
  /* -ms-overflow-style: none;  /* Internet Explorer 10+ */
  /* scrollbar-width: none;  */
}

/* #root::-webkit-scrollbar {
  display: none;
  width: 0 !important;
  width: 0 !important;
  background-color: transparent; /* optional */
/* } */ 