.container {
    position: sticky;
    top: 100%;
    opacity: 0.5;
    color: black;
    margin-left: 3%;
    padding-bottom: .5em;
    bottom: .5em;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.link {
    opacity: 60%;
    text-decoration: none;
    color: inherit;
    text-decoration: underline;  
    margin-right: 1em;
}

@media only screen and (max-width: 1200px) {
    .container {
        padding-left: 0;
        padding-right: 7%;
    }

    .link {
        font-size: .6em;
    }
}