.container {
    padding-top: 4%;
    padding-right: 50%;
    padding-left: 15%;
    padding-bottom: 2em;
    width: 70vw;
    max-width: 1600px;
    align-items: center;
    /* align-self: center; */
}

.header {
    font-size: 3em;
    font-weight: bold;
}

@media only screen and (max-width: 1200px) {
    .container {
        padding-top: 7%;
        padding-right: 4%;
        padding-left: 4%;
    }

    .header {
        text-align: center;
    }
}