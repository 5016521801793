.daukeySpecial {
  background-color: #00bbff;
}

.creatorLogo {
  /* border: 3px black solid; */
  margin-right: 1em;
  border-radius: 5%;
  /* box-shadow:
    6px 6px 0px 0px #fff inset,
    -6px -6px 0px 0px rgba(0, 0, 0, 0.29) inset; */
}

.creatorDescription {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: space-between;
  flex: 1;
}

.userMessage {
  max-width: 31em;
}

.section {
  height: auto;
  display: flex;
  flex-direction: row;

  align-items: center;
  align-content: center;
}

.socialScroll {
  color: blue;
  display: flex;
  /* justify-self: center; */
}

.link {
  color: inherit;
  text-decoration: underline;
}

.spacer { 
  flex: 1;
  flex-grow: 1;
}

@media only screen and (max-width: 1200px) {
  .creatorLogo {
    height: 3em;
    width: 3em;
    align-self: flex-start;
    justify-self: center;
    /* border: 3px black solid; */
    margin-right: 1em;
    /* box-shadow:
      3px 3px 0px 0px #fff inset,
      -3px -3px 0px 0px rgba(0, 0, 0, 0.29) inset; */
  }

  .creatorDescription {
    font-size: .75em;
  }

  .socialScroll {
    font-size: .75em;
  }

}
