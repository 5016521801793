.container {
    /* height: 100%; */
    padding-left: 15%;
    padding-right: 15%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;
}

.section {
    display: flex;
    flex-direction: row;
}

.text {
    font-size: 3em;
}

.image {
    max-width: 100%;
    height: auto;
}

@media only screen and (max-width: 1200px) {
    .text {
        font-size: 1em;
    } 

    .image {
        margin-top: 2em;
    }

    .container {
        /* height: 100%; */
        padding-left: 7%;
        padding-right: 7%;
    }
}

/* .navButton {
    width: 20em;
} */