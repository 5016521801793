.container {
    padding-top: 4%;
    padding-right: 50%;
    padding-left: 15%;
    padding-bottom: 2em;
}

.header {
    font-size: 3em;
    font-weight: bold;
    margin-bottom: .25em;
}

@media only screen and (max-width: 1400px) {
    .container {
        padding-top: 7%;
        padding-right: 4%;
        padding-left: 4%;
    }

    .header {
        text-align: center;
    }
}