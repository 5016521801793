.container {
    padding-top: 4%;
    padding-left: 15%;
    padding-right: 15%;
    display: flex;
    flex-grow: 1;
    justify-content: start;
}

.window {
    /* display: flex; */
    /* padding: 12px 16px; */
    /* background: #C3C3C3; */
    /* box-shadow: 4px 4px 2.4px 0px rgba(255, 255, 255, 0.59) inset, -2px -2px 4px 0px rgba(0, 0, 0, 0.25) inset, 4px 4px 4px 0px rgba(0, 0, 0, 0.25); */
}  

.title {
    font-size: 2em;
    font-weight: bold;
    /* margin-right: 1em; */
    /* margin-left: 16px; */
    line-height: 1;
    margin-top: .2em;
    margin-bottom: .05em;
}

.titleBar {
    background: #0827F5;
  }
  

.copy {
    max-width: 60%;
}

.orderTable {
    display: flex;
    line-height: 0;
    flex-direction: column;
}

.tableName {
    font-weight: 900;
}

.rowLabel {
    width: 8em;
    text-align: end;
    text-align: end;
}

.orderTableRow {
    /* font-size: smaller; */
    display: flex;
    flex-direction: row;
}

.creatorLogo {
    height: 3em;
    width: 3em;
}

.creatorLogoHalo {
    margin-left: 1em;
    padding: .5em;
    background: radial-gradient(50% 50% at 50% 50%, #0BF 31.04%, rgba(0, 187, 255, 0.00) 100%);
}

.section {
    display: flex;
    flex-direction: row;
  
    align-items: center;
    align-content: center;
  }

.daukey_header {
    border-color: #00BBFF !important;
}

.socialTitle {
    font-size: 2em;
    color: black;
    font-weight: 700;
}

@media only screen and (max-width: 1400px) {
    .creatorLogo {
        height: 2em;
        width: 2em;
    }

    .titleBar {
        margin-bottom: 1em;
    }

    .container {
        padding-top: 7%;
        padding-left: 7%;
        padding-right: 7%;
    }

    .tableName {
        font-weight: normal;
    }

    .title {
        font-size: 1.5em;
        line-height: normal;
        /* text-align: center; */
    }
    .copy {
        max-width: 100%;
        margin-top: 0;
        margin-bottom: 0;
    }

    .orderTable {
        line-height: normal;
    }

    .rowLabel {
        margin-top: 0;
        margin-bottom: 0;
        width: 7em;
        text-align: end;
    }

    .text {
        /* font-size: larger; */
        margin-top: 0;
        margin-bottom: 0;
    }

    .socialTitle { 
        /* text-align: center; */
    }

}

@media only screen and (max-width: 400px) {
    .orderTableRow {
        /* font-size: x-small; */
    }

    .title {
        text-align: left;
    }
}