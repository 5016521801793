.container {
    position: relative;
    width: 100%;
}

.limitCount {
    position: absolute;
    bottom: 5px;
    right: 10px;
    color: #666;
    /* font-size: 0.8em; */
}

