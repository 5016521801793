
.hideme {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none; 
}

.hideme::-webkit-scrollbar {
    display: none;
    width: 0 !important;
    background-color: transparent; /* optional */
}