.header {
    font-size: 1.25em;
    display: flex;
    font-weight: bold;
    align-items: center;
    flex-direction: row;
    height: 3em;
    max-width: 1600px;
    width: 70vw;
    /* margin-left: 15%s; */
    /* padding-right: 15%; */
    align-self: center;
    /* background: #DDD; */
    /* border-bottom: .15em solid black; */
}


@media only screen and (max-width: 1200px) {
    .header {
        margin-left: 1%;
        /* -right: 13%; */
        /* width: 98%; */
        width: 100%;
    }

    .header {
        /* display: none; */
    }
}