.styled-button {
    box-sizing: border-box;
    height: 3.5em;
    color: black;
    font-weight: bold;
    padding-left: 1em;
    padding-right: 1em;
    border: 2px solid rgba(0, 0, 0, 0.75);
    border-radius: 3px;
    background: var(--orange-500, #F45D01);
    box-shadow: 4px 4px 0px 0px rgba(255, 255, 255, 0.80) inset, -4px -4px 0px 0px rgba(0, 0, 0, 0.25) inset;
}

.styled-button:active {
    box-shadow: 7px 7px 0px 0px rgba(255, 255, 255, 0.80) inset, -8px -8px 0px 0px rgba(0, 0, 0, 0.25) inset; 
    transform: translateY(1px) translateX(1px); /* Move the button slightly down and to the right */
}

.styled-button:hover {
    box-shadow: 7px 7px 0px 0px rgba(255, 255, 255, 0.45) inset, -8px -8px 0px 0px rgba(0, 0, 0, 0.10) inset; 
}

/* .styled-button-disabled {
    background: grey;
} */
