.container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  /* padding-left: 15%; */
  /* padding-right: 15%; */
  width: 70vw;
  max-width: 1600px;
  align-self: center;
}

.creatorDescription {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}

.copy {
  font-weight: bold;
}

.section {
  display: flex;
  flex-direction: row;

  align-items: center;
  align-content: center;
}

.textArea {
  height: 25em;
  width: 100%;
  resize: none;
  /* box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;  */
}

.header {
  font-weight: bold;
  font-size: 2.5em;
}

.orderCTA {
  justify-content: flex-end;
  margin-top: 1em;
}

.socialLink {
  text-decoration: none !important;
}

.link {
  color: inherit;
  text-decoration: underline;
}

.outline {
  border: 0.1em solid orangered;
  /* color: orangered; */
  padding: 1em;
}

.titleBar {
  /* background: #0827F5; */
}

.window {
  padding: 12px 16px;
  /* background: #C3C3C3; */
  /* box-shadow: 4px 4px 2.4px 0px rgba(255, 255, 255, 0.59) inset, -2px -2px 4px 0px rgba(0, 0, 0, 0.25) inset, 4px 4px 4px 0px rgba(0, 0, 0, 0.25); } */
}
.username {
  color: black;
  font-size: 2em;
  font-weight: bold;
  margin-right: 1em;
  /* margin-left: 16px; */
  /* margin-top: .5em; */
  margin-bottom: 0;
}

.socialScroll {
  color: blue;
  display: flex;
  justify-self: center; 
}

.userMessage {
  max-width: 31em;
}

.partnerTag {
  margin: 0;
  color: white;
  font-weight: bold;
  background-color: black;
  padding: 3px 12px;
  border-radius: 5px;
  
  /* border: 1px solid #000; */
/* background: linear-gradient(265deg, rgba(128, 128, 128, 0.20) 20.51%, rgba(255, 255, 255, 0.20) 62.07%), #FFF9C6; */
/* box-shadow: 0px 0px 10px 2px rgba(255, 247, 225, 0.75), 4px 4px 0px 0px rgba(255, 255, 255, 0.76) inset, -4px -4px 0px 0px rgba(0, 0, 0, 0.23) inset; */
}

.creatorLogo {
  border: 3px black solid;
  margin-right: 1em;
  box-shadow: 6px 6px 0px 0px #FFF inset, -6px -6px 0px 0px rgba(0, 0, 0, 0.29) inset; 
}

.creatorLogoHalo {
  margin-left: 1em;
  padding: 0.5em;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #0bf 31.04%,
    rgba(0, 187, 255, 0) 100%
  );
}

.daukey_header {
  border-color: #00bbff !important;
}

.daukey_input {
  border-color: #00bbff !important;
}

.daukeySpecial {
  background-color: #00bbff;
}

.agreement {
  margin-bottom: 1em;
  min-width: 43em;
  text-align: start;
  max-width: 43em;
  font-size: .9em;
}

.grower {
  flex-grow: 1;
}

.orderButton {
  width: 20em;
  margin-left: 2em;
}

.loadingAnimation {
  margin-right: 1.5em;
}

.contactForm {
  max-width: 50em;
  display: flex;
  flex-direction: column;
}

.contactNameMeta {
  font-size: small;
  line-height: 0%;
  width: 100%;
  text-align: end;
}

.input {
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .creatorLogo {
    height: 3em;
    width: 3em;
    align-self: flex-start;
    justify-self: center;
  }

  .contactForm {
    width: 100%;
  }

  .partnerTag {
    font-size: .75em;
  }

  .username {
    font-size: 1em;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .agreement {
    font-size: 12px;
  }

  .container {
    padding-left: 4%;
    padding-right: 4%;
    margin-bottom: 1em;
    width: 100%;
  }

  .header {
    font-size: 1em;
    text-align: center;
  }

  .copy {
    font-size: 12px;
  }

  .textArea {
    height: 20em;
    font-size: .75em;
    /* box-shadow: .25em .25em .25em 0px rgba(0, 0, 0, 0.25) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset; */
    /* box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.25) inset, 0px 2px 2px 0px rgba(0, 0, 0, 0.25) inset;  */
  }

  .price {
    font-size: 1.2em;
  }

  .orderCTA {
    margin-top: 0em;
    font-size: .75em;
    flex-direction: column;
    align-items: center;
  }

  .orderButton {
    width: 100%;
    margin-left: 0;
  }

  .loadingAnimation {
    margin-top: 2em;
    width: 100%;
    align-content: center;
    align-items: center;
    max-height: 5em;
  }

  .creatorDescription {
    font-size: .75em;
  }

  .socialScroll {
    font-size: .75em;
  }

  .creatorLogo {
    /* border: 3px black solid; */
    margin-right: 1em;
    /* box-shadow: 3px 3px 0px 0px #FFF inset, -3px -3px 0px 0px rgba(0, 0, 0, 0.29) inset;  */
  }

  .grower {
    display: none;
  }

  .agreement {
    text-align: center;
    word-wrap:break-word;
    max-width: inherit;
    min-width: inherit;
    margin-top: 1em;
  }
}

@media only screen and (max-height: 670px) {
  .header {
    margin: 0;
    /* color:#0BF;  */
    /* line-height: 0; */
  }

  .agreement {
    padding: 0;
    margin: 0;
    margin-top: 1em;
  }
}