.creatorLogo {
    height: 4em;
    width: 4em;
    border-radius: 5%;
  }

  @media only screen and (max-width: 1200px) {
    .creatorLogo {
      height: 3em;
      width: 3em;
    }
}