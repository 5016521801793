.container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding-right: 15%;
    padding-left: 15%;
    /* margin-top: 8%; */
    /* align-items: center; */
    /* justify-content: center;/ */
}

.loading {
    display: flex;
    align-self: center;
    text-align: center;
    justify-self: center;
    flex-grow: 1;
}

.section {
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    max-width: max-content;
}

.section * {
    margin-right: .5em;
}

.sectionBorder {
    border: 0.1em solid;
}

.header {
    font-size: 3em;
    margin-bottom: 0;
}

.formTextInput {
    /* max-width: 27em; */
    margin-top: .5em;
    margin-bottom: .5em;
    width: 24em;
}

.formError {
    border-color: orangered;
    /* color: orangered; */
}

.formSubmit {
    margin-top: 1em;
    width: 24em;
    max-width: 27em;
}

.callToAction {
    display: flex;
    max-width: max-content;
    flex-direction: column;
}

.requestLink {
    margin-top: 1em;
}

.confirmation {
    margin-top: 2em;
}

.sectionHeader { 
    font-size: larger;
}

@media only screen and (max-width: 1200px) {
    
    .container {
        margin-top: 0;
        font-size: .9em;
        padding-left: 7%;
        padding-right: 7%;
    }

    .section {
        flex-direction: column;
        max-width: 100%;
        /* text-align: center; */
        /* align-content: center; */
        margin-bottom: .25em;
    }

    .section * {
        align-content: center;
        justify-content: flex-start;
    }

    .header { 
        margin-top: .5em;
        margin-bottom: .25em;
        font-size: 1.5em;
        text-align: center;
    }

    .confirmation {
        text-align: center;
    }

    .formSubmit {
        max-width: 100%;
        width: 100%;
    }

    .callToAction {
        max-width: none;
    }

    .formTextInput {
        max-width: 100%;
        width: 100%;
    }

    .formTextInput {
        padding-top: .5em;
        padding-bottom: .5em;
    }

    .requestLink {
        margin-top: .85em;
        text-align: center;
        margin-bottom: .5em;
    }

    .input {
        margin-top: .4em;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}